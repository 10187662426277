
import { UpdateUser } from "@/@types/register";
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/auth";
import MemberService from "@/services/members";
import { deleteNullProp, getPhoneNumber3, getPostalCode2, parsePhoneNumber } from "@/utils/Util";
@Component
export default class MyPageEditForm extends Vue {
  private get me() {
    return AuthModule.me;
  }
  private loading = false;
  private mounted() {
    if (this.me) {
      this.form = { ...this.me };
      if (this.me.tel) this.phoneNumber = getPhoneNumber3(this.me.tel);
      if (this.me.zip_code) this.postalCode = getPostalCode2(this.me.zip_code);
    }
  }
  private form: UpdateUser = {
    last_name: "",
    first_name: "",
    last_name_kana: "",
    first_name_kana: "",
    zip_code: "",
    address: "",
    tel: "",
    email: "",
  };

  private phoneNumber = {
    number1: "",
    number2: "",
    number3: "",
  };
  private postalCode = {
    number1: "",
    number2: "",
  };

  private zipValidateOn = false;
  @Watch("postalCode", { deep: true })
  private changeZipValidateOn() {
    this.zipValidateOn = true;
    if (!this.postalCode.number1 && !this.postalCode.number2) {
      this.zipValidateOn = false;
    }
  }

  private async submit() {
    try {
      this.loading = true;
      if (this.me) {
        this.form.tel =
          this.phoneNumber.number1 && this.phoneNumber.number2 && this.phoneNumber.number3
            ? parsePhoneNumber(this.phoneNumber.number1 + this.phoneNumber.number2 + this.phoneNumber.number3)
            : "";
        if (this.postalCode.number1 && this.postalCode.number2)
          this.form.zip_code = `${this.postalCode.number1}-${this.postalCode.number2}`;
        const payload = deleteNullProp(this.form);
        const res = await MemberService.update(this.me.id, payload);
        const user = res.data.data[0];
        AuthModule.changeMe(user);
        window.scrollTo(0, 0);
        this.$router.push({ name: "MyPage" });
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;

      throw new Error(error);
    }
  }
}
