
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MyPageEditForm from "@/components/forms/MyPageEditForm.vue";
import AuthModule from "@/store/auth";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  name: "MyPageEdit",
  components: {
    Header,
    Footer,
    MyPageEditForm,
  },
})
export default class MyPageEdit extends CheckCommonLogin {
  private get me() {
    return AuthModule.me;
  }

  private mounted() {
    if (this.me == null) {
      this.$router.push({ name: "Login" });
    }
  }
}
